import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 5×2\\@60%1RM w/ :05 descent & :01 ascent`}</p>
    <p>{`Shoulder Bridge 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`15-Box Jumps (24/20)`}</p>
    <p>{`15-Back Squats (135/95)(RX+ 185/125)`}</p>
    <p>{`15-Calorie Assault Bike`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      